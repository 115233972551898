import { adjustCaretPosition, conformToMask } from 'text-mask-core';
import { isAppNative } from './app-native';
import { maskCNPJ } from './cnpj-utils';
import { maskCPF } from './cpf-utils';
import { RegexDate } from './regular-expressions';

export function base64Encode(s) {
  return btoa(s);
}

export function base64Decode(s = '') {
  if (!s || typeof s !== 'string') return '';
  try {
    return atob(s);
  } catch (e) {
    console.error('Erro ao decodificar base64:', e);
    return '';
  }
}

export const camelize = (str) =>
  `${str}`
    .toLowerCase()
    .replace(/(?:(^.)|(\s+.))/g, function (match) {
      return match.toUpperCase();
    })
    .replace(/\b(d?[a,e,i,o,u])\b/gi, function (exp) {
      return exp.toLowerCase();
    });

export const firstName = (str) =>
  camelize(`${str}`).replace(/^([^ ]+) ?.*$/gi, '$1');

export const money = (str) => {
  const currencySymbol = 'R$';

  if (!str) str = `0.00`;
  if (`${str}`.slice(`${str}`.length - 3)[0] === ',') {
    str = `${str.replace(/\./g, '').replace(/,/g, '.')}`;
  }
  const value = (str * 1).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });

  return `${currencySymbol} ${value.replace(/[^0-9,.-]/g, '')}`;
};

export const moneyFormat = (str, signal = true) => {
  const currencySymbol = 'R$';
  const isNegative = parseFloat(str) < 0;
  const value = Math.abs(parseFloat(str))
    .toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    })
    .replace(/[^0-9,.-]/g, '');

  return `${isNegative && signal ? '-' : ''} ${currencySymbol} ${value}`;
};

export const moneyToFloat = (str) => {
  if (typeof str === 'string' || str instanceof String) {
    return parseFloat(
      str.replace(new RegExp('\\.', 'g'), '').replace(new RegExp('\\,'), '.')
    );
  }

  return str;
};

export const newMoneyToFloat = (str = '') => {
  return Number(str.replace(/[^0-9,-]+/g, '').replace(',', '.'));
};

export const toUTC = (date) =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

export const dateSubtractDays = (date, days) => {
  if (!date) return '';
  const dayValue = new Date(formatDateStringToIsoDateString(date));
  const msDay = 1000 * 60 * 60 * 24;
  const calc = days * msDay;
  const format = dayValue.setMilliseconds(dayValue.getMilliseconds() - calc);

  return !isNaN(format) ? dateFormat(format) : '';
};

export const dateFormatIso = (str) =>
  new Date(str).toISOString().replace(/T.+$/g, '');

export const dateFormat = (str, locale = 'pt-br') => {
  return toUTC(new Date(str)).toLocaleDateString(locale);
};

export const timeFormat = (str, locale = 'pt-br') => {
  const date = new Date(str);
  const hours = toUTC(date).getHours();
  const minutes = toUTC(date)
    .getMinutes()
    .toString()
    .replace(/^(\d)$/, '0$1');

  return `${hours}h${minutes}`;
};

export const timeHourFormat = (str, locale = 'pt-br') => {
  const padZero = (num) => (num < 10 ? `0${num}` : num);
  const date = new Date(str);
  const hours = padZero(toUTC(date).getHours());
  const minutes = padZero(toUTC(date).getMinutes());
  const seconds = padZero(toUTC(date).getSeconds());

  return `${hours}:${minutes}:${seconds}`;
};

export function dateFormatTemp(dataString, showTemp = true, fullMonth = true) {
  const data = new Date(toISOStringFixedEndDay(dataString));
  const dataAtual = new Date(toISOStringFixedEndDay(new Date()));

  const currentTemp = () => {
    const mesmoDia =
      data.getDate() === dataAtual.getDate() &&
      data.getMonth() === dataAtual.getMonth() &&
      data.getFullYear() === dataAtual.getFullYear();

    const futuro = data > dataAtual;
    const passado = data < dataAtual;

    if (mesmoDia && !futuro && !passado) {
      return ' (Hoje)';
    } else if (futuro) {
      return ' (Futuro)';
    } else if (passado) {
      return '';
    }
  };

  const meses = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ];

  const dia = ('0' + data.getDate()).slice(-2);
  const mes = fullMonth
    ? meses[data.getMonth()]
    : data.getMonth() < 9
    ? `0${data.getMonth() + 1}`
    : data.getMonth() + 1;
  const ano = data.getFullYear();

  const formattedDate = fullMonth
    ? `${dia} ${mes} ${ano}`
    : `${dia}/${mes}/${ano}`;

  return showTemp ? `${formattedDate}${currentTemp()}` : formattedDate;
}

export const dateFormatLong = (str, locale = 'pt-br') =>
  toUTC(new Date(str)).toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

export const dateFormatLongNotYear = (str, locale = 'pt-br') =>
  toUTC(new Date(str)).toLocaleDateString(locale, {
    month: 'long',
    day: 'numeric',
  });

export const dateFormatWeekDay = (str) => {
  const days = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ];

  return days[toUTC(new Date(str)).getDay()];
};

export const dateTimeFormat = (str, useUTC = true, locale = 'pt-br') =>
  !str
    ? '-'
    : (useUTC ? toUTC(new Date(str)) : new Date(str)).toLocaleString(locale);

export const toISOStringFixedEndDay = (date) => {
  if (!date) return;

  const pad = (number) => {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  };
  const newDate = date && typeof date === 'string' ? new Date(date) : date;

  return (
    newDate.getUTCFullYear() +
    '-' +
    pad(newDate.getUTCMonth() + 1) +
    '-' +
    pad(newDate.getUTCDate()) +
    'T23:59:59.000Z'
  );
};

export const toISOStringFixedStartDay = (date) => {
  if (!date) return;

  const pad = (number) => {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  };
  const newDate = date && typeof date === 'string' ? new Date(date) : date;

  return (
    newDate.getUTCFullYear() +
    '-' +
    pad(newDate.getUTCMonth() + 1) +
    '-' +
    pad(newDate.getUTCDate()) +
    'T00:00:00.000Z'
  );
};

export const onlyNumbers = (str) => `${str}`.replace(/[^0-9]/g, '');
export const onlyDecimals = (str) =>
  `${str}`.replace(',', '.').replace(/[^0-9.]/g, '');

export const onlyAlphaNumeric = (str) => `${str}`.replace(/[^a-zA-Z0-9]/g, '');

export const accountClean = (str) => `${str}`.replace(/(\.0+?)*$/gi, '');

const fallbackCopyTextToClipboardApp = (text = '') => {
  window.ReactNativeWebView?.postMessage(`clipboard?${text}`);
};

const fallbackCopyTextToClipboard = (text) => {
  const textArea = document.createElement('textarea');

  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';
  textArea.style.opacity = '0';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Oops, unable to copy', err);
  }
  document.body.removeChild(textArea);
};

export const copyTextToClipboard = (text) => {
  if (isAppNative()) {
    fallbackCopyTextToClipboardApp(text);
    return;
  }

  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  navigator.clipboard.writeText(text).then(
    () => {},
    (err) => {
      console.error('Async: Could not copy text: ', err);
    }
  );
};

export const actOnEnter = (e, action) => {
  if (e.charCode === 13) {
    e.preventDefault();
    if (action) action();
  }
};

export const mailize = (str) =>
  `${str}`.toLowerCase().replace(/^.+?(@.+)/g, '**********$1');

export const numberPad = (str) => `${str}`.padStart(2, '0');

export const validateCEP = (str) =>
  `${str}`.toLowerCase().replace(/[^0-9]/g, '').length === 8;

export const toCepFormat = (str) =>
  `${str}`.replace(/^([\d]{2})\.*([\d]{3})-*([\d]{3})/, '$1.$2-$3');

export const toCepFormatAlt = (str) =>
  `${str}`.replace(/^([\d]{5})-*([\d]{3})/, '$1-$2');

const toPhoneNumberWith12Digits = (str) =>
  `${str}`.replace(/(\d{2,3})((\d|\*){4,5})((\d|\*){0,4})$/, '($1) $2 $4');

const toPhoneNumberWith11Digits = (str) =>
  `${str}`.replace(/(\d{2})((\d|\*){4,5})((\d|\*){0,4})$/, '($1) $2 $4');

const toPhoneNumberWith10Digits = (str) =>
  `${str}`.replace(/(\d{2})((\d|\*){4})((\d|\*){0,4})$/, '($1) $2 $4');

const toPhoneNumberFormatMoreThanTenDigits = (str) =>
  str.length === 11
    ? toPhoneNumberWith11Digits(str)
    : toPhoneNumberWith12Digits(str);

const toPhoneNumberFormatMoreThanNineDigits = (str) =>
  str.length === 10
    ? toPhoneNumberWith10Digits(str)
    : toPhoneNumberFormatMoreThanTenDigits(str);

export const toPhoneNumberFormat = (str) =>
  `${str}`.length > 9 ? toPhoneNumberFormatMoreThanNineDigits(str) : str;

export const toAccountBankNumberWithDigit = (str) =>
  `${str}`.replace(/(\d+)(\d){1}$/, '$1-$2');

export const isValidDate = (str = '') => {
  let data, dia, mes, ano;

  if (str?.indexOf('/') > 0) {
    data = `${str}`.split('/');
    dia = data[0];
    mes = data[1];
    ano = data[2];
  } else if (str?.indexOf('-') > 0) {
    data = `${str}`.split('-');
    dia = data[2];
    mes = data[1];
    ano = data[0];
  }

  const hasSize = data && data.length && data.length === 3;

  if (!hasSize) return false;

  const validMonth = mes * 1 > 0 && mes * 1 <= 12;

  if (!validMonth) return false;

  const validYear = ano * 1 > 0 && ano.length === 4 && ano > 1920;

  if (!validYear) return false;

  const isLeap = ano % 4 === 0;
  const months = [
    0,
    31,
    isLeap ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ];
  const validDay = dia * 1 <= months[mes * 1] && dia * 1 > 0;

  if (!validDay) return false;

  return true;
};

export const validateEmail = (mail) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gim.test(
    mail
  );
};

export const validateText = (value = '') => {
  const reg = new RegExp("[a-zA-Zaâáàäãeêéèëiîíìïoôóòöõuûúùücç.']+");

  return reg.test(value);
};

export const validateName = (name = '') => {
  const letter = new RegExp("[a-zA-Zaâáàäãeêéèëiîíìïoôóòöõuûúùücç.']+");
  const number = new RegExp(/[\d]/);
  const names = name.trim().split(' ');
  const isSingleName = names.length < 2;

  if (isSingleName) return false;

  const validations = names.map(
    (name) => letter.test(name) && !number.test(name)
  );

  const isValidNames = !validations.includes(false);

  return isValidNames;
};

export const clearURL = (url) => url.replace(/\/:[^/]+?(\/|$)/gi, '');

export const toCurrencyNumber = (data) =>
  Number(`${data}`.replace(/\./g, '').replace(',', '.'));

export const toMask = (value, mask = [], placeholder) =>
  conformToMask(value, mask, placeholder);

export const toAccountNumber = (value) => {
  const mask = (value) => {
    const tempMask = [];

    if (value && onlyNumbers(value).length > 2) {
      for (let it = 2; it < onlyNumbers(value).length; it++) {
        tempMask.push(/\d/);
      }
    }
    tempMask.push(/\d/, '-', /\d/);
    return tempMask;
  };

  return toMask(value, mask(value)).conformedValue;
};

export const caretPosition = (
  previousConformedValue,
  conformedValue,
  rawValue,
  placeholder = ''
) =>
  adjustCaretPosition({
    previousConformedValue,
    conformedValue,
    rawValue,
    placeholder,
    currentCaretPosition: rawValue && rawValue.length ? rawValue.length : 0,
  });

export const updateMaskedInputCaretPosition = (element, defaultValue, mask) => {
  const previousConformedValue = defaultValue;
  const rawValue = onlyNumbers(defaultValue);
  const conformedValue = toMask(rawValue, mask).conformedValue;

  const position = caretPosition(
    previousConformedValue,
    conformedValue,
    rawValue
  );

  element.value = conformedValue;
  element.setSelectionRange(position, position);
};

export const removeYearsFromDate = (date, totalYears) => {
  date.setFullYear(date.getFullYear() - totalYears);
  return date;
};

export const validateDateWithMinAndMax = (dateString, minDate, maxDate) => {
  const currentDate = new Date(dateString);

  minDate = typeof minDate === 'string' ? new Date(minDate) : minDate;
  maxDate = typeof maxDate === 'string' ? new Date(maxDate) : maxDate;
  const isMoreThanMaxDate = maxDate.getTime() < currentDate.getTime();
  const isMinorThanMinDate = minDate.getTime() > currentDate.getTime();

  return isMoreThanMaxDate || isMinorThanMinDate;
};

export const isValidStringDate = (value = '') =>
  RegexDate.dateLocaleBR.test(value);

const localeBRtoIsoDateString = (value = '') => {
  const splitted = value.split('/');
  const day = splitted[0];
  const month = splitted[1];
  const year = splitted[2];

  const newIsoDateString = `${year}-${month}-${day}`;

  return newIsoDateString;
};

export const formatDateStringToIsoDateString = (value = '') => {
  const isLocalBRDate = RegexDate.dateLocaleBR.test(value);

  return isLocalBRDate ? localeBRtoIsoDateString(value) : value;
};

export const removeSpecialLetters = (text = '') =>
  text
    .toLowerCase()
    .normalize('NFD')
    .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
// .replace(/[a,â,á,à,ä,ã]/g, 'a')
// .replace(/[e,ê,é,è,ë]/g, 'e')
// .replace(/[i,î,í,ì,ï]/g, 'i')
// .replace(/[o,ô,ó,ò,ö,õ]/g, 'o')
// .replace(/[u,û,ú,ù,ü]/g, 'u')
// .replace(/[c,ç]/g, 'c')

export const compareTwoStrings = (value1, value2) => {
  const val1 = `${value1}`.replace(/\s+/g, '').toLowerCase();
  const val2 = `${value2}`.replace(/\s+/g, '').toLowerCase();

  return val1 === val2;
};

export function compareTwoFormatStrings(value1, value2) {
  const val1 = removeSpecialLetters(value1).replace(/\s/g, '');
  const val2 = removeSpecialLetters(value2).replace(/\s/g, '');

  return val1 === val2;
}

export const validateRandomPixKey = (key) => {
  const value = `${key}`.replace(/-/g, '');

  return value.length === 32 && value.match(/^[0-9a-zA-Z]+$/);
};

export const maskCpfOrCnpj = (value, hideChar) => {
  const numbers = onlyNumbers(`${value}`);

  if (numbers.length < 11) return value;

  if (hideChar === 'CPF' && numbers.length === 11)
    return (
      '***.' + numbers.substring(3, 6) + '.' + numbers.substring(6, 9) + '-**'
    );

  const maskFunction = numbers.length > 11 ? maskCNPJ : maskCPF;

  return maskFunction(numbers);
};

export const docType = (doc = '') => {
  if (doc.length < 11) return '';
  return doc.length > 11 ? 'CNPJ' : 'CPF';
};

export const unMask = (value) => {
  value = value || '';
  return value.trim().replace(/[^A-Za-z0-9]+/g, '');
};

export const toDecimal = (
  value = 0,
  minFractionDigits = 2,
  locale = 'pt-br'
) => {
  value = typeof value === 'number' ? value : Number(value);

  return value.toLocaleString(locale, {
    style: 'decimal',
    minimumFractionDigits: minFractionDigits,
  });
};

export const formatPercentage = (number) => {
  if (typeof number !== 'number') {
    return 'Invalid Input';
  }
  return number.toFixed(2) + '%';
};

export const classNames = (values = []) =>
  values.filter((v) => v !== undefined && v !== null && !!v).join(' ');

export function maskCardNumber(str) {
  if (/^\d{4}\d+\d{4}$/.test(str)) {
    return str.replace(/^(\d{4})\d+(\d{4})$/, '$1XXXXXXXXX$2');
  } else {
    return str;
  }
}

export const accountNumberFormat = (str) => {
  return str ? `${str}`.replace(/-/g, '').replace(/(.)$/, '-$1') : false;
};
